<template>
  <div class="CooperativeItem">
    <div class="itemBox">
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: 'firmInfo' }"
            >合作企业</el-breadcrumb-item
          >
          <el-breadcrumb-item>员工发展路径列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="Detail">
        <CardHeader :title="'员工发展路径'" :pt="0" :pb="24" />
        <div class="itemLists" v-if="PageList && PageList.length > 0">
          <ItemListCard
            :Path="'developmentPathInfo'"
            :data="PageList"
            type="calendar"
          ></ItemListCard>
        </div>
        <div class="itemLists" v-else>
          <div class="noData">暂无数据~</div>
        </div>
        <div class="loading" v-if="PageList && PageList.length >= 1">
          <Pagination
            :total="totalCount"
            :limit="16"
            :layout="'prev, pager, next'"
            :autoScroll="false"
            @pagination="areaPagination"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

import ItemListCard from "@/components/Common/ItemListCard.vue";
import CardHeader from "@/components/Common/CardHeader.vue";
import { dateTimeFilter } from "@/filters";
import { getPageList } from "@/api/enterprise/developmentPath";
export default {
  name: "DevelopmentPath",
  props: {},
  components: {
    CardHeader,
    ItemListCard,
    Pagination
  },
  data() {
    return {
      loading: false,
      totalCount: 0,
      PageList: null,
      queryData: {
        EnterpriseId: this.$route.query.id,
        PageSize: 10,
        PageIndex: 1
      },
      Resume: {}
    };
  },
  computed: {
    formartDate() {
      return (data, formate) => dateTimeFilter(data, formate);
    }
  },
  watch: {},
  methods: {
    //
    async getPageList() {
      this.loading = true;
      try {
        const { Result } = await getPageList(this.queryData);
        this.PageList = Result.Data;
        this.totalCount = Result.TotalCount;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    areaPagination(val) {
      this.queryInvestmentData.PageIndex = val.page;
      this.getInvestmentList();
    }
  },
  created() {
    this.getPageList();
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
.CooperativeItem {
  width: 100%;
  min-height: calc(100vh - 202px);
  background: #f5f5f5;
}
.itemBox {
  box-sizing: border-box;
  width: 1100px;
  margin: 0 auto;
  padding-bottom: 40px;
  /deep/ .el-breadcrumb__item:nth-child(2) {
    color: #111;
  }
  /deep/ .el-breadcrumb__inner {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bfbfbf;
    line-height: 20px;
  }
  .breadcrumb {
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    padding: 16px 0 8px;
  }
  .Detail {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 32px;
    min-height: 66vh;
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    .itemLists {
      min-height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      .noData {
        color: #3d5afe;
      }
    }
  }
}
.loading {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
